







import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AuthLayoutLogo extends Vue {
  /**
   * Props
   */
  @Prop({ default: true, type: Boolean }) private expanded!: boolean;

  /**
   * Class names
   */
  private get classNames(): object {
    return {
      expanded: this.expanded,
    };
  }
}
