





























































import { Component, Vue } from 'vue-property-decorator';

import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Auth from '@/app/lib/auth/Auth';
import { redirect } from '@/shared/lib/support/router/RouterUtils';
import Alert from '@/shared/resources/components/Alert.vue';
import AuthLayoutLogo from '@/app/layouts/auth/AuthLayoutLogo.vue';

@Component({
  components: {
    AuthLayoutLogo,
    Alert,
    GridCol,
    Card,
    Button,
    Divider,
    InputField,
  },
})
export default class AuthLoginForm extends Vue {
  /**
   * Data
   */
  private credentials: any = { // TODO add interface
    email: '',
    password: '',
  };
  private loading: boolean = false;
  private errorMessage: string | null = null;

  /**
   * Display getters
   */
  private get displayError(): boolean {
    return !!this.errorMessage;
  }

  /**
   * Methods
   */
  private async attemptLogin() {
    this.loading = true;
    this.errorMessage = null;

    try {
      await Auth.login(this.credentials.email, this.credentials.password);
      redirect(this.$route.query.redirect || { name: 'dashboard' });
    } catch (ex) {
      this.loading = false;

      if (ex.isExpectationFailed() || ex.isUnprocessableEntity()) {
        this.errorMessage = 'Niepoprawny login lub hasło.';
      } else {
        this.errorMessage = 'Wystąpił nieoczekiwany błąd. Prosimy o&nbsp;kontakt.';
      }
    }
  }

  /**
   * Handlers
   */
  private onFormSubmit(e: Event) {
    e.preventDefault();
    this.attemptLogin();
  }
}
